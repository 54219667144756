<template>
    <div class="homeOrder" v-loading="loading">
        <div class="title">
            <span v-if="$route.query.type == 'select'">选择地址</span>
            <span v-else>我的地址</span>
            <div class="add" @click="add">添加地址</div>
        </div>
        <div v-if="addressList.length == 0"
            style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
            <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
            <div style="width: 100%;margin-top: 10px;">暂无数据</div>
        </div>
        <div class="address" v-for="(i, index) in addressList" :key="index">
            <div class="content" @click="selectClick(i)">
                <div class="img">{{ i.name.charAt(0) }}</div>
                <div class="info">
                    <div class="name">{{ i.name }} <span style="margin-left: 5px;">{{ i.telephone }}</span></div>
                    <div class="addr">{{ i.province }}{{ i.city }}{{ i.county }}{{ i.address }}</div>
                </div>
            </div>
            <div class="operation">
                <div class="mrdz" @click="isSelectedClick(i)">
                    <div class="img">
                        <img v-if="i.defaults == 1" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="text">默认地址</div>
                </div>
                <div class="caoz">
                    <el-button type="text" style="color: #666666;" @click="dele(i.addressId)">删除</el-button>
                    <el-button type="text" style="color: #333333;" @click="edit(i)">编辑</el-button>
                </div>
            </div>
        </div>

        <!-- 添加地址对话框 -->
        <el-dialog title="添加地址" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="收货人姓名：" prop="name"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone" style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="送货地址：" prop="receivingInformation"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-cascader :options="options" :props="{ value: 'label', label: 'label' }"
                        v-model="ruleForm.receivingInformation" style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="detailedAddress"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="ruleForm.detailedAddress"></el-input>
                </el-form-item>
                <div class="mrdz" @click="isSelectedClickAdd">
                    <div class="img">
                        <img v-if="isSelectedAdd" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="text">默认地址</div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑地址对话框 -->
        <el-dialog title="编辑地址" :visible.sync="dialogVisibleEdit" width="50%" :close-on-click-modal="false"
            :show-close="false">
            <el-form :model="editAddressInfo" :rules="editAddressInfoRules" ref="editAddressInfo" label-position="top"
                label-width="100px" class="demo-ruleForm">
                <!-- ---------------------------  地址回显问题 el-cascader （已解决） 编辑无法修改问题  -------------------------------------------------------------------------- -->
                <el-form-item label="收货人姓名：" prop="name"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-input v-model="editAddressInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="telephone"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="editAddressInfo.telephone"></el-input>
                </el-form-item>
                <el-form-item label="送货地址：" prop="receivingInformation"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-cascader :options="options" :props="{ value: 'label', label: 'label' }"
                        v-model="editAddressInfo.addArr" style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="editAddressInfo.address"></el-input>
                </el-form-item>
                <!-- ----------------------------------------------------------------------------------------------------- -->
                <div class="mrdz" @click="isSelectedClickEdit">
                    <div class="img">
                        <img v-if="editAddressInfo.defaults == '1'" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="text">默认地址</div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('editAddressInfo')">取 消</el-button>
                <el-button type="primary" @click="submitForm('editAddressInfo')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 分页 -->
        <paging style="width: auto !important;" :total="paging.total" @handleCurrentChange="handleCurrentChange"
            :page-size="paging.size"></paging>
    </div>
</template>

<script>
import { postAddress, getAddress, postAddressDelete, getOrderSelectAddreee } from "@/utils/api/myApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'myAddress',
    components: {
        paging
    },
    data() {
        return {
            isSelected: true,
            // 添加地址时是否默认地址
            isSelectedAdd: false,
            // 添加对话框
            dialogVisible: false,
            // 编辑对话框
            dialogVisibleEdit: false,
            // 全国地址数据
            options: [],
            // 添加地址数据
            ruleForm: {
                name: '',
                phone: '',
                receivingInformation: '',
                detailedAddress: ''
            },
            // 校验
            rules: {
                name: [
                    { required: true, message: '请输收货人名称', trigger: 'blur' },
                    { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确格式手机号', trigger: 'blur' }
                ],
                receivingInformation: [
                    { required: true, message: '请选择收货地址', trigger: 'change' },
                ],
                detailedAddress: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
            },
            editAddressInfoRules: {
                name: [
                    { required: true, message: '请输收货人名称', trigger: 'blur' },
                    { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
                ],
                telephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确格式手机号', trigger: 'blur' }
                ],
                // receivingInformation: [
                //     { required: true, message: '请选择收货地址', trigger: 'change' },
                // ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
            },
            // 类型 添加 - 编辑
            type: 'add',
            // 分页
            paging: {
                page: 1,
                size: 10,
                total: 0
            },
            // 地址列表
            addressList: [],
            // 加载中
            loading: true,
            // 编辑地址信息
            editAddressInfo: {},
            addreeeId: '',
        }
    },
    mounted() {
        this.options = this.$addresses
        this.getAddressList()
    },
    methods: {
        // 选择地址事件
        async selectClick(i) {
            if (this.$route.query.type != 'select') return
            let oid = this.$route.query.oid
            console.log(i, oid)
            let data = i
            data.oId = oid
            data.oStatus = 'DFH'
            data.uName = i.name
            data.uTelephone = i.telephone
            console.log(data)
            const res = await getOrderSelectAddreee(data)
            if (res.data) {
                this.$message.success('修改成功')
                // this.$router.push('/myIndex/myOrder?actMyNum=0')
                window.history.back();
            } else {
                this.$message.error('修改失败')
            }
        },
        // 获取地址列表
        async getAddressList() {
            const res = await getAddress({
                start: this.paging.page,
                length: this.paging.size
            })
            if (res.statusCode == 8201) {
                this.addressList = res.data.data
                this.paging.total = res.data.total
                this.loading = false
            }
        },
        // 默认地址
        async isSelectedClick(val) {
            console.log(val)
            let data = {
                addressId: val.addressId,
                defaults: 1,
                channel: 'mall',
                type: 'edit'
            }
            console.log(data)
            const res = await postAddress(data)
            if (res.statusCode == 8201) {
                this.$message.success("已设为默认地址")
                this.getAddressList()
            } else {
                this.$message.error(res.message)
            }
        },
        // 点击编辑
        edit(i) {
            this.editAddressInfo = { ...i }
            this.dialogVisibleEdit = true
            this.type = 'edit'
            let addArr = []
            addArr.push(i.province)
            addArr.push(i.city)
            addArr.push(i.county)
            this.editAddressInfo.addArr = addArr
            this.addreeeId = i.addressId
            console.log(i)
        },
        // 点击添加
        add() {
            this.dialogVisible = true
            this.type = 'add'
        },
        // 添加地址时选择是否默认
        isSelectedClickAdd() {
            this.isSelectedAdd = !this.isSelectedAdd
        },
        // 删除地址
        dele(ids) {
            this.$confirm('确认删除该地址吗?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await postAddressDelete(ids)
                if (res.statusCode == 8201) {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.getAddressList()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 确定按钮
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    if (formName == 'ruleForm') {
                        let data = {
                            pcc: this.ruleForm.receivingInformation.join(' '),
                            name: this.ruleForm.name,
                            province: this.ruleForm.receivingInformation[0],
                            city: this.ruleForm.receivingInformation[1],
                            county: this.ruleForm.receivingInformation[2],
                            address: this.ruleForm.detailedAddress,
                            telephone: this.ruleForm.phone,
                            defaults: this.isSelectedAdd ? 1 : 0,
                            channel: 'mall',
                            type: this.type
                        }
                        console.log(data)
                        const res = await postAddress(data)
                        if (res.statusCode == 8201) {
                            this.$message.success(res.message)
                            this.dialogVisible = false
                            this.getAddressList()
                        } else {
                            this.$message.error(res.message)
                        }
                    } else {
                        // 编辑对话框走这里
                        let data = {
                            addressId: this.addreeeId,
                            pcc: this.editAddressInfo.addArr.join(' '),
                            name: this.editAddressInfo.name,
                            province: this.editAddressInfo.addArr[0],
                            city: this.editAddressInfo.addArr[1],
                            county: this.editAddressInfo.addArr[2],
                            address: this.editAddressInfo.address,
                            telephone: this.editAddressInfo.telephone,
                            defaults: this.editAddressInfo.defaults,
                            channel: 'mall',
                            type: this.type
                        }
                        console.log(data)
                        const res = await postAddress(data)
                        if (res.statusCode == 8201) {
                            this.$message.success(res.message)
                            this.dialogVisibleEdit = false
                            this.getAddressList()
                        } else {
                            this.$message.error(res.message)
                        }
                    }

                } else {
                    console.log('error submit!!', valid);
                    return false;
                }
            });
        },
        // 取消，重置按钮
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.isSelectedAdd = false
            this.dialogVisibleEdit = false
        },
        isSelectedClickEdit() {
            if (this.editAddressInfo.defaults == '1') {
                this.editAddressInfo.defaults = '0'
            } else {
                this.editAddressInfo.defaults = '1'
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getAddressList()
        },
    }
}

</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        >.add {
            width: 80px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FF4242;
            font-size: 12px;
            font-weight: 400;
            color: #FF4242;
            text-align: center;
            line-height: 30px;
            margin-top: -5px;
            cursor: pointer;
        }
    }

    >.address {
        width: 100%;
        height: 120px;
        background-color: #fff;
        // padding: 12px;
        box-sizing: border-box;
        margin-top: 20px;

        >.content {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;
            padding: 0 12px;
            box-sizing: border-box;

            >.img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: #FFCB9A;
                text-align: center;
                line-height: 36px;
                color: #FF531D;
                font-size: 16px;
            }

            >.info {
                font-size: 12px;
                color: #666;
                margin-left: 10px;

                >.name {
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
            }
        }

        >.operation {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;

        }
    }
}

.mrdz {
    width: 100px;
    display: flex;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    user-select: none;

    >.img {
        width: 16px;
        height: 16px;
        margin-right: 7px;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/deep/ .el-button--primary {
    background-color: #FF4242 !important;
    border: 1px solid #FF4242;
}

/deep/ .el-form--label-top .el-form-item__label {
    padding: 0 !important;
}
</style>